import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import s from './EmbeddedVideo.module.css';
import { useRouter } from 'next/router';

import {
    EmbeddedVideoProps,
    autoPlayProps,
} from '../../types/components/video';
import { getConsent } from '@/utils/userCentrics';

const EmbeddedVideo = ({
    src,
    autoPlay,
    controls,
    muted,
}: EmbeddedVideoProps) => {
    const [hasWindow, setHasWindow] = useState(false);
    const [allowVideoLoading, setAllowVideoLoading] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHasWindow(true);
        }
    }, []);

    useEffect(() => {
        // usercentrics check if video can be loaded
        setAllowVideoLoading(getConsent('YouTube Video'));
        window.addEventListener('UC_UI_CMP_EVENT', function () {
            setTimeout(() => {
                setAllowVideoLoading(getConsent('YouTube Video'));
            }, 500);
        });
    }, []);

    if (!hasWindow) {
        // Returns null on first render, so the client and server match
        return null;
    }

    let autoPlayAttr: autoPlayProps = {
        muted: muted,
    };

    if (autoPlay === true) {
        autoPlayAttr = {
            playing: true,
            loop: true,
            muted: true,
            rel: 0,
        };
    }

    return (
        <>
            {src && hasWindow && allowVideoLoading ? (
                <div className={s.EmbeddedVideo}>
                    <ReactPlayer
                        url={src}
                        width="100%"
                        height="100%"
                        controls={controls}
                        config={{
                            youtube: {
                                embedOptions: {
                                    host: 'https://www.youtube-nocookie.com',
                                },
                            },
                        }}
                        {...autoPlayAttr}
                    />
                </div>
            ) : (
                <div className={s.EmbeddedVideo__nocookies}>
                    {router.locale == 'nl' ? (
                        <p>
                            Helaas zijn de cookies momenteel niet geaccepteerd,
                            waardoor deze content niet zichtbaar is.
                            <br /> Klik hieronder op Privacy settings om je
                            cookie-instellingen te wijzigen en toegang te
                            krijgen tot de volledige inhoud van onze website.
                        </p>
                    ) : (
                        <p>
                            Unfortunately, cookies are currently not accepted,
                            which makes this content not visible.
                            <br /> Click below on Privacy settings to change
                            your cookie settings and gain access to the full
                            content of our website.
                        </p>
                    )}
                    {/* eslint-disable-next-line */}
                    <a
                        href="#"
                        onClick={() => (window as any).UC_UI.showSecondLayer()}>
                        Privacy Settings
                    </a>
                </div>
            )}
        </>
    );
};

export default EmbeddedVideo;

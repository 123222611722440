import { getRequest, postRequest } from './requests';
import { PollToSubmit } from '../types/components/poll';

const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_WAGTAIL_API_URL;

export async function getPollResults(pollId: number) {
    const responses = await getRequest(
        `${NEXT_PUBLIC_API_URL}/v1/poll/${pollId}/`
    );
    return responses.json;
}

export async function postPoll(formData: PollToSubmit) {
    const response = await postRequest(`${NEXT_PUBLIC_API_URL}/v1/poll/`, {
        answers: formData.answers,
        poll: formData.poll,
    });
    return response.json;
}

import React, { useState, useEffect } from 'react';
import s from './HeroBlock.module.css';
import ButtonBlock from '../ButtonBlock/ButtonBlock';

import { WbHero } from '@digital-engineering-experiences/wb-webcomponents';
import { HeroBlockProps } from '@/types/components/blocks';
import { getConsent } from '@/utils/userCentrics';

const HeroBlock = ({ data }: { data: HeroBlockProps }) => {
    const [allowVideoLoading, setAllowVideoLoading] = useState(false);
    let backgroundImage;
    let video;

    if (data.media[0].type === 'video') {
        video = data.media[0].value.url;
        backgroundImage = data.media[0].value?.image.img.src;
    } else {
        video = undefined;
        backgroundImage = data.media[0].value?.img?.src;
    }

    useEffect(() => {
        // usercentrics check if video can be loaded
        setAllowVideoLoading(getConsent('YouTube Video'));
        window.addEventListener('UC_UI_CMP_EVENT', function () {
            setTimeout(() => {
                setAllowVideoLoading(getConsent('YouTube Video'));
            }, 500);
        });
    }, []);

    return (
        <WbHero
            className={s.heroBlock}
            backgroundImage={backgroundImage}
            title={data.title}
            gradient={true}
            fixedheight={data.fixedHeight}
            text={data.text}
            video={allowVideoLoading ? video : undefined}>
            {data.buttonBlock.length !== 0 && (
                <ButtonBlock value={data.buttonBlock[0].value} />
            )}
        </WbHero>
    );
};

export default HeroBlock;

import React, { useState, useEffect } from 'react';
import { RawHtmlProps } from '../../types/components/blocks';
import { getConsent } from '@/utils/userCentrics';

const RawHtml = ({ html }: RawHtmlProps) => {
    const [allowVideoLoading, setAllowVideoLoading] = useState(false);

    useEffect(() => {
        const links = document.querySelectorAll('.richtexteditor a');
        links.forEach((link) => {
            const href = link.getAttribute('href');
            if (href && isExternal(href)) {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
            }
        });
    }, [html]);

    useEffect(() => {
        const iframes = html.includes('iframe');
        // usercentrics check if video can be loaded
        if (iframes) {
            setAllowVideoLoading(getConsent('YouTube Video'));
            window.addEventListener('UC_UI_CMP_EVENT', function () {
                setTimeout(() => {
                    setAllowVideoLoading(getConsent('YouTube Video'));
                }, 500);
            });
        } else {
            setAllowVideoLoading(true);
        }
    }, []);

    const isExternal = (url: string) => {
        return url.startsWith('http') || url.startsWith('//');
    };

    if (allowVideoLoading) {
        return (
            <div
                className="richtexteditor"
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    } else {
        return <></>;
    }
};

export default RawHtml;

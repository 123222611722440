import React, { useState, useEffect } from 'react';
import s from './PollBlock.module.css';
import {
    PollBlockProps,
    PollBlockAnswerProps,
} from '@/types/components/blocks';
import { PollToSubmit } from '@/types/components/poll';
import { postPoll } from '@/api/poll';
import { WbButton } from '@digital-engineering-experiences/wb-webcomponents';
import EmbeddedImage from '@/components/Image/Image';

const PollBlock = ({
    data,
    withinColumn = false,
}: {
    data: PollBlockProps;
    withinColumn?: boolean;
}) => {
    const [selectedAnswer, setSelectedAnswer] = useState<Array<number>>([]);
    const [pollresults, setPollresults] = useState<Array<any>>(
        data?.pollResultsSummary
    );
    const [sumVotes, setSumVotes] = useState<number>(
        data?.pollResultsSummary?.reduce((sum, result) => sum + result.count, 0)
    );
    const [hasVoted, setHasVoted] = useState<boolean>(false);

    useEffect(() => {
        const votedPolls = JSON.parse(
            localStorage.getItem('votedpolls') || '[]'
        );
        if (votedPolls.includes(data.id)) {
            setHasVoted(true);
        } else if (data.endDate && new Date(data.endDate) < new Date()) {
            setHasVoted(true);
        } else {
            setHasVoted(false);
        }
    }, [data.id]);

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (data.inputType === 'checkbox') {
            setSelectedAnswer((prevSelected) =>
                event.target.checked
                    ? [...prevSelected, value]
                    : prevSelected.filter((answer) => answer !== value)
            );
        } else {
            setSelectedAnswer([value]);
        }
    };

    async function submitResponse(data: PollToSubmit) {
        try {
            const response = await postPoll(data);
            response.pollResults &&
                (setPollresults(response.pollResults),
                setSumVotes(
                    response.pollResults?.reduce(
                        (sum: number, result: any) => sum + result?.count,
                        0
                    )
                ));
        } catch (error) {
            console.log('response could not be posted');
        }
    }

    const PollresultItem = ({
        answer,
        index,
    }: {
        answer: PollBlockAnswerProps;
        index: number;
    }) => {
        const relativeAmount =
            sumVotes == 0
                ? 0
                : ((pollresults.find((result) => result.answer == answer.id)
                      ?.count || 0) /
                      sumVotes) *
                  100;

        return (
            <div className={s.pollBlock__results__answer}>
                <label
                    className={s.pollBlock__results__answer__label}
                    htmlFor={answer.id.toString()}>
                    <span>{answer.answer}</span>{' '}
                    <small>{relativeAmount.toFixed(1)} %</small>
                </label>
                <div className={s.pollBlock__results__answer__progressbar}>
                    <div
                        className={
                            s.pollBlock__results__answer__progressbar__inner
                        }
                        style={{
                            minWidth: `${100 - relativeAmount}%`,
                            animationDelay: `${index * 0.1}s`,
                        }}></div>
                </div>
            </div>
        );
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedAnswer.length > 0) {
            setHasVoted(true);
            const votedPolls = JSON.parse(
                localStorage.getItem('votedpolls') || '[]'
            );
            votedPolls.push(data.id);
            localStorage.setItem('votedpolls', JSON.stringify(votedPolls));

            submitResponse({
                poll: data.id,
                answers: selectedAnswer,
            });
        }
    };

    return (
        <div
            className={
                s.pollBlock +
                ' ' +
                (withinColumn == true ? s.pollBlock__column : ' wb-container ')
            }>
            <h3>{data.question}</h3>
            {!hasVoted ? (
                <form className={s.pollBlock__form} onSubmit={handleSubmit}>
                    <fieldset className={s.pollBlock__answers}>
                        {data.answers.map((answer, index) => (
                            <label className={s.pollBlock__label} key={index}>
                                {answer.image && (
                                    <EmbeddedImage
                                        imageDetails={answer.image.img}
                                    />
                                )}
                                <span className={s.pollBlock__label__inputrow}>
                                    <input
                                        type={data.inputType}
                                        name="poll"
                                        value={answer.id}
                                        onChange={handleAnswerChange}
                                    />
                                    <span>{answer.answer}</span>
                                </span>
                            </label>
                        ))}
                    </fieldset>
                    <WbButton
                        state={
                            selectedAnswer.length < 1 ? 'disabled' : 'default'
                        }
                        label={data.submitButtonText}></WbButton>
                </form>
            ) : (
                <>
                    {!data.showResults ? (
                        <span>{data.thankYouText}</span>
                    ) : (
                        <div className={s.pollBlock__results}>
                            {data.answers.map((answer, index) => (
                                <React.Fragment key={index}>
                                    <PollresultItem
                                        index={index}
                                        answer={answer}
                                    />
                                </React.Fragment>
                            ))}
                            <small className={s.pollBlock__results__sumvotes}>
                                {sumVotes} {data.timesVotedText}
                            </small>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default PollBlock;

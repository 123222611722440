import React from 'react';
import s from './TwoColumnBlock.module.css';
import EmbeddedVideo from '../../EmbeddedVideo/EmbeddedVideo';
import { PollBlockProps, TwoColumnBlockProps } from '@/types/components/blocks';
import { ImageData } from '@/types/components/image';
import { EmbeddedVideoProps } from '@/types/components/video';
import { WbTwocolumns } from '@digital-engineering-experiences/wb-webcomponents';
import RawHtml from '@/components/RawHtml';
import EmbeddedImage from '@/components/Image/Image';
import PollBlock from '../PollBlock/PollBlock';

const TwoColumnBlock = ({ data }: { data: TwoColumnBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';
    const columnLeft = data.columnLeft[0];
    const columnRight = data.columnRight[0];

    const renderContent = (content: {
        type: 'Text' | 'Image' | 'Video' | 'Poll';
        value: string | ImageData | EmbeddedVideoProps | any;
    }) => {
        if (content.type === 'Text') {
            return <RawHtml html={content.value as string} />;
        } else if (content.type === 'Image') {
            return (
                <EmbeddedImage
                    data={content.value as ImageData}
                    imageDetails={(content.value as ImageData).image.img}
                />
            );
        } else if (content.type === 'Video') {
            return (
                <EmbeddedVideo
                    src={(content.value as EmbeddedVideoProps).src}
                    autoPlay={(content.value as EmbeddedVideoProps).autoPlay}
                    controls={(content.value as EmbeddedVideoProps).controls}
                    muted={(content.value as EmbeddedVideoProps).muted}
                />
            );
        } else if (content.type === 'Poll') {
            return (
                content.value && (
                    <PollBlock
                        data={content.value as PollBlockProps}
                        withinColumn={true}
                    />
                )
            );
        }
    };

    return (
        <div className={backgroundcolor}>
            <div className={s.twocolumnsBlock}>
                <WbTwocolumns
                    inverse={data.columnOrder == 'invert' && true}
                    variant={data.grid}
                    leftcolumn={renderContent(columnLeft)}
                    rightcolumn={renderContent(columnRight)}></WbTwocolumns>
            </div>
        </div>
    );
};

export default TwoColumnBlock;

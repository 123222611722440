import React from 'react';
import { InlinePortalBlockProps } from '@/types/components/blocks';
import dynamic from 'next/dynamic';
import s from './InlinePortalBlock.module.css';
import { WbLoader } from '@digital-engineering-experiences/wb-webcomponents';

const DynamicInlinePortalBlock = dynamic(() => import('./InlinePortalBlock'), {
    ssr: false,
});

const InlinePortalWrapper = ({ data }: { data: InlinePortalBlockProps }) => {
    return (
        <div className={s.InlinePortalBlockContainer}>
            <div
                style={{ height: data.height }}
                className={s.InlinePortalBlockWrapper}>
                <React.Suspense fallback={<WbLoader />}>
                    <DynamicInlinePortalBlock data={data} />
                </React.Suspense>
            </div>
        </div>
    );
};
export default InlinePortalWrapper;

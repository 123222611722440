type ServiceKey = keyof typeof serviceKeys;
const serviceKeys = {
    'ArcGIS JavaScript API': 'x7s0W-VqVeRf6L',
    'ArcGIS JavaScript Initialisatiescript': '4WrAzQ68LseGWV',
    'Microsoft Forms CDN': 'cReJZmGBCl3Gfs',
    'Usercentrics Consent Management Platform': 'H1Vl5NidjWX',
    'ArcGIS REST API': 'G8GouagOs0zwSO',
    'ArcGIS Service': 'Q52qFGPJC2MtHj',
    'ArcGIS Tile Service': 'XCgL1_QfnswV2Z',
    'ArcGIS Tile Service (nSZVuSZjHpEZZbRo)': 's-XTcF3fchQYGy',
    'Azure Application Insights': 'ahyn7EyxgGCdJX',
    'Azure Monitoring Script': 'thqvOc9J07u2Z2',
    'Microsoft Forms API': 'sgTU92KsL5hSZi',
    'Microsoft Forms Response Page': 'ruqMMRraCwBrXj',
    'Microsoft Forms Tracking Pixel': 'aiCspx7NSy4EMX',
    'Microsoft Office Tracking Pixel': 'g_GTZLVjbgFBjd',
    'Microsoft OneCollector': 'cy6POXHRn-XDDc',
    Vimeo: 'HyEX5Nidi-m',
    'YouTube Video': 'BJz7qNsdj-7',
    'Zeroheight Design Tokens API': 'yZzaE97rD_pGrC',
};

export function getConsent(service: ServiceKey) {
    const serviceKey = serviceKeys[service];
    if (!serviceKey) {
        return false;
    }
    const ucData = localStorage.getItem('ucData');
    // usercentrics stores preferences in ucData, so loop through it to find the correct consent

    if (ucData) {
        const parsedData = JSON.parse(ucData);
        return parsedData.consent.services[serviceKey]?.consent;
    } else {
        return false;
    }
}

import React from 'react';
import s from './VideoBlock.module.css';
import EmbeddedVideo from '../../EmbeddedVideo/EmbeddedVideo';
import { VideoBlockProps } from '@/types/components/blocks';

const VideoBlock = ({ data }: { data: VideoBlockProps }) => {
    const backgroundcolor = data.bgcolor?.backgroundColor || '';

    return (
        <div className={backgroundcolor}>
            <div className={s.videoBlock}>
                <div
                    className={`wb-container ${s.videoBlock__container}`}
                    data-testid="embedded-video">
                    <EmbeddedVideo
                        src={data.src}
                        autoPlay={data.autoPlay}
                        controls={data.controls}
                        muted={data.muted}
                    />
                </div>
            </div>
        </div>
    );
};

export default VideoBlock;
